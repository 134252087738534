import React, { useState } from "react";
import { Button } from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "./../../api/client";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const CreateDelivery = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;

  const orderId = data?._id;

  const [employees, setEmployees] = useState();
  const [emp, setEmp] = useState();
  const [date, setDate] = useState(null);

  const handleChange = (event) => {
    setEmp(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to dispatch for delivery",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success && emp) {
        const result = await apiClient.post("/delivery/create-delivery", {
          orderId,
          employeeId: emp?._id,
          deliveryDate: date?.$d,
        });
        if (result.ok) {
          swal("Updated Order Successfully");
          navigate("/orders/list-delivery");
        }
      }
    });
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    const { data } = await apiClient.get("/employee");
    setEmployees(data);
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Orders</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => this.handleBack()}>
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/product/list">Orders</a>
            </li>
            <li className="breadcrumb-item active">Delivery Order</li>
          </ol>

          {/* Add prd  */}
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Update Order for delivery</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="row">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Order Id*</label>
                          <p>{orderId}</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Current Status</label>
                          <p>{data?.deliveryStatus}</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Select Delivery Man
                          </label>
                          <br />
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={emp}
                            label="Employee"
                            onChange={handleChange}
                            style={{ width: "200px" }}
                          >
                            {employees?.map((item, index) => {
                              return (
                                <MenuItem value={item}>{item.name}</MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Delivery Date</label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              // label=""
                              value={date}
                              onChange={(newValue) => {
                                setDate(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>

                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Dispatch
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CreateDelivery;
