import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import apiClient from "../api/client";

const AllReview = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [getList, setGetList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setIsLoaded(true);
    const { data } = await apiClient.get("/products/getrecentreviews");
    console.log(data);
    setGetList(data.product);
    setIsLoaded(false);
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Reviews</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Reviews</li>
          </ol>
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>All Reviews</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th>Product Id</th>
                        <th>Product Name</th>
                        <th>No of reviews</th>
                        <th>Rating</th>
                        <th>More</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true
                        ? getList?.map((row, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td>{row._id}</td>
                                  <td>{row.name}</td>
                                  <td>{row.reviews.length}</td>
                                  <td>{row?.rating} ⭐</td>
                                  <td>
                                    <Link to="/productreview" state={row}>
                                      <Typography className="edit-btn">
                                        <i className="fas fa-edit" />
                                      </Typography>
                                    </Link>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AllReview;
