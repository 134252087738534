import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import apiClient from "../api/client";
import swal from "sweetalert";

const ProductReview = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [getList, setGetList] = useState([]);
  const navigate = useNavigate();

  const { state } = useLocation();

  const handlDeleteById = async (id) => {
    const result = await apiClient.post("/products/deletereview", {
      id: state._id,
      reviewId: id,
    });
    if (result.ok) {
      swal("Delete Success");
      navigate("/reviews");
    }
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Reviews</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Reviews</li>
          </ol>
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>All Reviews</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th>Customer Name</th>
                        <th>Comment</th>
                        <th>Rating</th>
                        <th>Delete review</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true
                        ? state?.reviews.map((row, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td>{row.name}</td>
                                  <td>{row.comment}</td>
                                  <td>{row.rating}⭐</td>
                                  <td>
                                    <Typography
                                      className="delete-btn"
                                      onClick={(e) => handlDeleteById(row._id)}
                                    >
                                      <i className="fas fa-trash-alt" />
                                    </Typography>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ProductReview;
