import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import apiClient from "./../../api/client";
import { Typography } from "@mui/material";
import swal from "sweetalert";

const AllSubCategories = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [getList, setGetList] = useState([]);
  const [del, setDel] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, [del]);

  const fetchCategories = async () => {
    setIsLoaded(true);
    const { data } = await apiClient.get("/variations/get-sub-categories");

    setGetList(data);
    setIsLoaded(false);
  };

  const handlDeleteById = async (id) => {
    const result = await apiClient.delete("/variations/deletesubcategory", {
      id,
    });
    if (result.ok) {
      swal("Delete Success");
      setDel(!del);
    }
  };
  const handlActiveById = async (row) => {
    const result = await apiClient.post("/variations/activatesubcat", {
      catId: row._id,
      active: !row.active,
    });
    if (result.ok) {
      swal("Success");
      setDel(!del);
    }
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Categories</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Categories</li>
          </ol>
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>All Sub-Categories</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: 60 }}>Id no.</th>
                        <th style={{ width: 120 }}>Category Id no.</th>
                        <th style={{ width: 100 }}>Image </th>
                        <th style={{ width: 200 }}>Name</th>
                        <th>Description</th>
                        <th>Action Add Sale</th>
                        <th>Current Sale/Discount</th>
                        <th>Remove</th>
                        <th>Edit</th>
                        <th>Activate/Deactivate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true
                        ? getList.map((row, index) => (
                            <tr key={index}>
                              <td>{row._id}</td>
                              <td>{row.category}</td>
                              <td>
                                <div className="cate-img-5">
                                  <img src={row.photo} alt="product-name" />
                                </div>
                              </td>

                              <td>{row.name}</td>

                              <td>{row.description}</td>
                              <td className="action-btns">
                                <Link
                                  to="/category/sale"
                                  state={{ row, catname: "subcategory" }}
                                >
                                  <Typography className="edit-btn">
                                    <i className="fas fa-edit" />
                                  </Typography>
                                </Link>
                              </td>
                              <td>NA</td>
                              <td className="action-btns">
                                <Typography
                                  className="delete-btn"
                                  onClick={(e) => handlDeleteById(row._id)}
                                >
                                  <i className="fas fa-trash-alt" />
                                </Typography>
                              </td>
                              <td>
                                <Link
                                  to="/category/editsubcategory"
                                  state={row}
                                >
                                  <Typography className="edit-btn">
                                    <i className="fas fa-edit" /> edit
                                  </Typography>
                                </Link>
                              </td>
                              <td>
                                <Typography
                                  className="edit-btn"
                                  onClick={(e) => handlActiveById(row)}
                                >
                                  <i className="fas fa-edit" />{" "}
                                  {row.active == true ? "Active" : "Deactive"}
                                </Typography>
                              </td>
                            </tr>
                          ))
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AllSubCategories;
